<template>
  <v-row class="bbr-import-recipe">
    <v-col cols="12">
      <h3>Import Food Journal</h3>
    </v-col>
    <v-col cols="6">
      <v-form v-model="valid">
        <v-container>
          <v-file-input
            v-model="file"
            accept=".xls,.xlsx"
            label="Import food journal"
          >
          </v-file-input>
        </v-container>
        <v-btn class="mr-4" @click="importFile" :loading="loading">
          Submit
        </v-btn>
      </v-form>
    </v-col>
    <v-toast ref="toast" />
  </v-row>
</template>
<script>
import { mapActions } from 'vuex'
import { values, flattenDeep } from 'lodash'
import VToast from '@/components/elements/Toast'

export default {
  name: 'ImportFoodJournal',

  components: {
    VToast,
  },

  data() {
    return {
      loading: false,

      valid: null,
      file: null,
    }
  },
  methods: {
    ...mapActions({
      bulkImport: 'foods/bulkImport',
    }),

    importFile() {
      if (this.file == null) return

      let payload = new FormData()

      payload.append('excelFile', this.file)

      // Create record
      this.loading = true
      this.bulkImport(payload)
        .then((response) => {
          this.loading = false

          this.file = null

          this.$refs.toast.open({
            color: 'primary',
            message: 'Exporting food journal started!',
          })
        })
        .catch((err) => {
          this.loading = false
          if (err) {
            if (err.response.data !== undefined) {
              this.$refs.toast.open({
                color: 'red',
                timeout: 3000,
                message: flattenDeep(values(err.response.data.errors)).join(
                  ' '
                ),
              })
            }
          }
        })
    },
  },
}
</script>
